/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Space } from '../../../Widgets';

interface IPrivacyPolicyEN {
    className?: string;
}

const PrivacyPolicyEN: React.FC<IPrivacyPolicyEN> = ({ className }) => {
    return (
        <div className={className}>
            <ol>
                <li>
                    Information über die Erhebung, Verarbeitung und Löschung
                    personenbezogener Daten
                    <ul>
                        <li>
                            (1) Im Folgenden informieren wir über die Erhebung
                            personenbezogener Daten bei Nutzung unserer Website.
                            Personenbezogene Daten sind alle Daten, die auf Sie
                            persönlich beziehbar sind, z. B. Name, Adresse,
                            E-Mail-Adressen, Nutzerverhalten.
                        </li>
                        <li>
                            (2) Verantwortlicher gem. Art. 4 Abs. 7
                            EU-Datenschutz-Grundverordnung (DSGVO) ist die
                            BONMEA GmbH; Rat Beil Straße 3, 60320 Frankfurt am
                            Main, info@bonmeafoods.com, +49-945-088-72.
                        </li>
                        <li>
                            (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail
                            oder über ein Kontaktformular werden die von Ihnen
                            mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr
                            Name und Ihre Telefonnummer) von uns gespeichert, um
                            Ihre Fragen zu beantworten. Die in diesem
                            Zusammenhang anfallenden Daten löschen wir, nachdem
                            die Speicherung nicht mehr erforderlich ist, oder
                            schränken die Verarbeitung ein, falls gesetzliche
                            Aufbewahrungspflichten bestehen.
                        </li>
                        <li>
                            (4) Falls wir für einzelne Funktionen unseres
                            Angebots auf beauftragte Dienstleister zurückgreifen
                            oder Ihre Daten für werbliche Zwecke nutzen möchten,
                            werden wir Sie untenstehend im Detail über die
                            jeweiligen Vorgänge informieren. Dabei nennen wir
                            auch die festgelegten Kriterien der Speicherdauer.
                        </li>
                    </ul>
                </li>
                <li>
                    Ihre Rechte
                    <ul>
                        <li>
                            (1) Sie haben gegenüber uns folgende Rechte
                            hinsichtlich der Sie betreffenden personenbezogenen
                            Daten: <br />– Recht auf Auskunft, <br />– Recht auf
                            Berichtigung oder Löschung, <br />– Recht auf
                            Einschränkung der Verarbeitung, <br />– Recht auf
                            Widerspruch gegen die Verarbeitung, <br />– Recht
                            auf Datenübertragbarkeit.
                        </li>
                        <li>
                            (2) Sie haben zudem das Recht, sich bei einer
                            Datenschutz-Aufsichtsbehörde über die Verarbeitung
                            Ihrer personenbezogenen Daten durch uns zu
                            beschweren.
                        </li>
                    </ul>
                </li>
                <li>
                    Erhebung personenbezogener Daten bei Besuch unserer Website
                    <ul>
                        <li>
                            Bei der bloß informatorischen Nutzung der Website,
                            also wenn Sie sich nicht registrieren oder uns
                            anderweitig Informationen übermitteln, erheben wir
                            nur die personenbezogenen Daten, die Ihr Browser an
                            unseren Server übermittelt. Wenn Sie unsere Website
                            betrachten möchten, erheben wir die folgenden Daten,
                            die für uns technisch erforderlich sind, um Ihnen
                            unsere Website anzuzeigen und die Stabilität und
                            Sicherheit zu gewährleisten (Rechtsgrundlage ist
                            Art. 6 Abs. 1 S. 1 lit. f DSGVO): <br />–
                            IP-Adresse, – Datum und Uhrzeit der Anfrage, <br />–
                            Zeitzonendifferenz zur Greenwich Mean Time (GMT),{' '}
                            <br />– Inhalt der Anforderung (konkrete Seite),{' '}
                            <br />– Zugriffsstatus/HTTP-Statuscode, <br />–
                            jeweils übertragene Datenmenge, <br />– Website, von
                            der die Anforderung kommt, <br />– Browser, <br />–
                            Betriebssystem und dessen Oberfläche, <br />–
                            Sprache und Version der Browsersoftware.
                        </li>
                    </ul>
                </li>
                <li>
                    Kontaktformular auf unserer Website
                    <ul>
                        <li>
                            (1) Neben der rein informatorischen Nutzung unserer
                            Website bieten wir ein Kontaktformular, an, dass Sie
                            bei Interesse zur Kontaktaufnahme mit uns nutzen
                            können. Dazu müssen Sie in der Regel weitere
                            personenbezogene Daten angeben, die wir zur
                            Erbringung der jeweiligen Leistung nutzen und für
                            die die zuvor genannten Grundsätze zur
                            Datenverarbeitung gelten.
                        </li>
                        <li>
                            (2) Teilweise bedienen wir uns zur Verarbeitung
                            Ihrer Daten externer Dienstleister. Diese wurden von
                            uns sorgfältig ausgewählt und beauftragt, sind an
                            unsere Weisungen gebunden und werden regelmäßig
                            kontrolliert.
                        </li>
                        <li>
                            (3) Soweit unsere Dienstleister ihren Sitz in einem
                            Staat außerhalb des Europäischen Wirtschaftsraumen
                            (EWR) haben, informieren wir Sie über die Folgen
                            dieses Umstands in der Beschreibung des Angebotes.
                            Für den Fall, dass Ihre personenbezogenen Daten in
                            einen Staat außerhalb des EWR übertragen werden,
                            stellen wir sicher, dass eine solche Übertragung
                            durch vertragliche Maßnahmen abgedeckt ist (zum
                            Beispiel durch Nutzung der Standardklauseln der
                            Europäischen Kommission) und dass die
                            Datenverarbeitung und der Datenschutz den
                            gesetzlichen Anforderungen entsprechen. Sie können
                            eine Kopie der entsprechenden
                            Standardvertragsklauseln (falls anwendbar) erhalten,
                            indem Sie uns über die in Ziffer 1 Abs. (2) dieser
                            Datenschutzerklärung genannten Kontaktdaten
                            kontaktieren.
                        </li>
                    </ul>
                </li>
                <li>
                    Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
                    <ul>
                        <li>
                            Sofern Ihre personenbezogenen Daten auf Grundlage
                            von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1
                            lit. f DSGVO verarbeitet werden, haben Sie das
                            Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
                            Verarbeitung Ihrer personenbezogenen Daten
                            einzulegen, soweit dafür Gründe vorliegen, die sich
                            aus Ihrer besonderen Situation ergeben oder sich der
                            Widerspruch gegen Direktwerbung richtet. Im
                            letzteren Fall haben Sie ein generelles
                            Widerspruchsrecht, das ohne Angabe einer besonderen
                            Situation von uns umgesetzt wird. Möchten Sie von
                            Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
                            machen, genügt eine E-Mail an info@bonmeafoods.com.
                        </li>
                    </ul>
                </li>
                <li>
                    Datensicherheit
                    <ul>
                        <li>
                            Wir bedienen uns im Übrigen geeigneter technischer
                            und organisatorischer Sicherheitsmaßnahmen, um Ihre
                            Daten gegen zufällige oder vorsätzliche
                            Manipulationen, teilweisen oder vollständigen
                            Verlust, Zerstörung oder gegen den unbefugten
                            Zugriff Dritter zu schützen. Unsere
                            Sicherheitsmaßnahmen werden entsprechend der
                            technologischen Entwicklung fortlaufend verbessert.
                        </li>
                    </ul>
                </li>
                <li>
                    Aktualität und Änderung dieser Datenschutzerklärung
                    <ul>
                        <li>
                            (1) Diese Datenschutzerklärung ist aktuell gültig
                            und hat den Stand April 2021. (2) Durch die
                            Weiterentwicklung unserer Website und Angebote
                            darüber oder aufgrund geänderter gesetzlicher
                            beziehungsweise behördlicher Vorgaben kann es
                            notwendig werden, diese Datenschutzerklärung zu
                            ändern. Die jeweils aktuelle Datenschutzerklärung
                            kann jederzeit auf der Website unter
                            https://www.bonmea.com/de/privacy-policy von Ihnen
                            abgerufen und ausgedruckt werden.
                        </li>
                    </ul>
                </li>
            </ol>

            <Space height={32} />

            <h5>Angaben gemäß § 5 TMG:</h5>
            <p>
                BONMEA GmbH
                <br /> Rat-Beil-Straße 3
                <br />
                60318 Frankfurt am Main Deutschland
                <br />
                Telefax +49 69 / 768 980 51
                <br />
                info@bonmeafoods.com
                <br />
                Registergericht: Amtsgericht Frankfurt am Main - HRB 110004
                USt-IdNr.: DE303878679
                <br />
                CEO: Dr. Marc Borner
            </p>

            <p>
                BONMEA Ltd
                <br /> Lister House, Liter Hill, Horthforth, Leeds, LS18 5AZ,
                United Kingdom
            </p>

            <h5>Haftung für Inhalte:</h5>
            <p>
                Alle Information auf dieser Webseite ist gründlich geprüft. Wir
                bemühen uns diese Information regelmäßig zu erweitern und zu
                aktualisieren. Eine Garantie für die Vollständigkeit,
                Genauigkeit und Zeitlosigkeit kann nicht übernommen werden.
                BONMEA GmbH und BONMEA Ltd schließen alle Gewährleistungen aus,
                die aus Schäden entstammen, welche direkt oder indirekt über
                diese Webseite erfolgen.
            </p>

            <h5>Copyright:</h5>
            <p>BONMEA GmbH - BONMEA Ltd</p>

            <Space height={40} />
        </div>
    );
};

export default PrivacyPolicyEN;
