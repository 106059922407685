/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Space } from '../../../Widgets';

interface IPrivacyPolicyEN {
    className?: string;
}

const PrivacyPolicyEN: React.FC<IPrivacyPolicyEN> = ({ className }) => {
    return (
        <div className={className}>
            <ol>
                <li>
                    Information about the collection, processing and deletion of
                    personal data
                    <ul>
                        <li>
                            (1) Below we inform about the collection of personal
                            data when using our website. Personal data is all
                            data that is personally available to you, eg. Name,
                            address, e-mail addresses, user behavior. (2)
                            Responsible acc. Article 4 (7) of the EU General
                            Data Protection Regulation (DSGVO) is BONMEA GmbH;
                            Schwindstrasse 13, 60325 Frankfurt am Main, Germany,
                            info@bonmeafoods.com, +49 69 - 768 980 - 50. (3)
                            When you contact us by e-mail or via contact form,
                            the information you provide (your e-mail address,
                            your name and telephone number if applicable) will
                            be stored by us to answer your questions. We delete
                            data that arises in this context after the storage
                            is no longer required, or limit the processing if
                            there are statutory retention requirements. (4) If
                            we rely on contracted service providers for
                            individual functions of our offer or if you wish to
                            use your data for advertising purposes, we will
                            inform you in detail below about the respective
                            transactions. In doing so, we also name specified
                            criteria for storage duration of your data.
                        </li>
                    </ul>
                </li>
                <li>
                    Your rights
                    <ul>
                        <li>
                            (1) You have the following rights with respect to
                            the personal data concerning you: - right of
                            information, - right of rectification or erasure, -
                            right of restriction of processing, - right to
                            object the processing of your data, - right of data
                            portability. (2) You also have the right to complain
                            to a data protection supervisory authority about our
                            processing of your personal data.
                        </li>
                    </ul>
                </li>
                <li>
                    Collection of personal data when visiting our website
                    <ul>
                        <li>
                            In the case of merely informative use of the
                            website, i.e. if you do not register or otherwise
                            provide us with information, we only collect the
                            personal data that your browser transmits to our
                            server. If you wish to view our website, we collect
                            the following data which is technically necessary
                            for us to inform you about our website and to ensure
                            its stability and security (legal basis is Art. 6
                            (1) sentence 1 lit. GDPR): - IP address, - date and
                            time of the request, - time zone difference to
                            Greenwich Mean Time (GMT), - content of the request
                            (concrete page), - access status / HTTP status code,
                            - each transferred amount of data, - website from
                            which the request comes - browser, - Operating
                            system and its interface, - Language and version of
                            the browser software.
                        </li>
                    </ul>
                </li>
                <li>
                    Contact form on our website
                    <ul>
                        <li>
                            (1) In addition to the purely informational use of
                            our website, we offer a contact form, which you can
                            use to contact us in case of interest. To do this,
                            you will generally need to provide other personal
                            information that we use to provide the service and
                            for which the aforementioned data processing
                            principles apply. (2) Partially we might process
                            your data with the help of external service
                            providers. These have been carefully selected and
                            commissioned by us, are bound by our instructions
                            and are regularly inspected. (3) As far as our
                            service providers have their seat in a country
                            outside the European Economic Area (EEA), we will
                            inform you about the consequences of this
                            circumstance in the description of the offer. In the
                            event that your personal data is transferred to a
                            State outside the EEA, we ensure that such transfer
                            is covered by contractual measures (for example, by
                            using the standard clauses of the European
                            Commission) and that data processing and data
                            protection are legal meet requirements. You may
                            receive a copy of the relevant standard contractual
                            clauses (if applicable) by contacting us via the
                            contact details listed in section 1, paragraph (2)
                            of this privacy policy.
                        </li>
                    </ul>
                </li>
                <li>
                    Opposition or revocation against the processing of your data
                    <ul>
                        <li>
                            If your personal data is processed on legitimate
                            interests in accordance with Art. 6 para. 1 sentence
                            1 lit. f GDPR , you have the right to file an
                            objection against the processing of your personal
                            data in accordance with Art. 21 GDPR provided that
                            there are reasons arising from your particular case
                            or the objection is directed against direct
                            advertising. In the latter case, you have a general
                            right of objection, which is implemented by us
                            without specifying any particular situation. If you
                            would like to exercise your right of revocation or
                            objection, please send an e-mail to
                            info@bonmeafoods.com.
                        </li>
                    </ul>
                </li>
                <li>
                    Data security
                    <ul>
                        <li>
                            We also take appropriate technical and
                            organizational security measures to protect your
                            data against accidental or intentional manipulation,
                            partial or total loss, destruction or against
                            unauthorized access by third parties. Our security
                            measures are continuously improved in line with
                            technological developments.
                        </li>
                    </ul>
                </li>
                <li>
                    Up-to-dateness and change of this data protection
                    explanation
                    <ul>
                        <li>
                            (1) This Privacy Policy is currently valid as of
                            August 2018. (2) As a result of the further
                            development of our website and offers thereof or due
                            to changed legal or official requirements, it may
                            become necessary to change this privacy policy. The
                            current privacy policy can be accessed and printed
                            out at any time on the website at
                            www.bonmeafoods.com/legal
                        </li>
                    </ul>
                </li>
            </ol>

            <Space height={32} />

            <h5>Service provider according to § 5 TMG:</h5>
            <p>
                BONMEA GmbH
                <br /> Rat-Beil-Strasse 3
                <br />
                60318 Frankfurt am Main Germany
                <br />
                Telefax +49 69 / 768 980 51
                <br />
                info@bonmeafoods.com
                <br />
                Registry Court: Amtsgericht Frankfurt am Main - HRB 110004
                USt-IdNr.: DE303878679
                <br />
                Chief Executive Officer: Dr. Marc Borner
            </p>

            <p>
                BONMEA Ltd
                <br /> Lister House, Liter Hill, Horthforth, Leeds, LS18 5AZ,
                United Kingdom
            </p>

            <h5>Liability:</h5>
            <p>
                All information on this website is carefully checked. We strive
                to expand this information regularly and to update it. A
                guarantee for the completeness, accuracy and timeliness cannot
                be granted. BONMEA GmbH excludes all liability for damages
                arising directly or indirectly from the use of this website.
            </p>

            <h5>Copyright:</h5>
            <p>BONMEA GmbH - BONMEA Ltd</p>

            <Space height={40} />
        </div>
    );
};

export default PrivacyPolicyEN;
