/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { PageHeader } from '../Widgets';
import { Container, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import * as Styles from './Styles/privacy.module.scss';
import PrivacyPolicyEN from '../components/Content/PrivacyPolicy/PrivacyPolicyEN';
import PrivacyPolicyDE from '../components/Content/PrivacyPolicy/PrivacyPolicyDE';

interface IPrivacyPolicyPage {}

const PrivacyPolicyPage: React.FC<IPrivacyPolicyPage> = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'privacy.pageTitle' })}
                description={intl.formatMessage({
                    id: 'privacy.metaDescription',
                })}
                lang={intl.locale}
            />

            <PageHeader
                title={intl.formatMessage({
                    id: 'privacy.bannerLabel',
                })}
            />

            <Container>
                <Row>
                    <Col
                        md={12}
                        className={classnames('pageContent', 'text-center')}>
                        <h1>{intl.formatMessage({ id: 'privacy.title' })}</h1>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {intl.locale === 'en' ? (
                            <PrivacyPolicyEN className={Styles.content} />
                        ) : (
                            <PrivacyPolicyDE className={Styles.content} />
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default PrivacyPolicyPage;
